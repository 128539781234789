import { useQuery } from '@apollo/client'
import React, { createContext, useContext, useEffect, useState } from 'react'

import USERINFO from 'components/theme/Header/UserNav/me.graphql'
import { KeyContext } from 'providers/KeyProvider'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
	const { api_key } = useContext(KeyContext)
	const [utmLinks, setUtmLinks] = useState('')
	const { data: userData = { me: {} }, loading } = useQuery(USERINFO, {
		skip: !api_key,
	})

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const referral_key = searchParams.get('referral_key')
		searchParams.delete('referral_key')
		const utmLinksParam = searchParams.toString()

		if (referral_key) {
			document.cookie = `barmej_referral_key=${referral_key};max-age=604800;domain=barmej.com`
		}
		setUtmLinks(utmLinksParam)
	}, [])

	return (
		<UserContext.Provider
			value={{
				utmLinks,
				userInfo: {
					...userData.me,
					userId: userData && userData.me && userData.me.id,
				},
				loading,
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
