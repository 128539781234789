import 'core-js/modules/es6.map'
import 'core-js/modules/es6.set'
import 'intl'
import React from 'react'
import objectFitImages from 'object-fit-images'
import Apollo from 'providers/Apollo'
import mixpanel from 'mixpanel-browser'
import { KeyProvider } from 'providers/KeyProvider'
import { UserProvider } from 'providers/UserProvider'
import 'raf/polyfill'
import 'url-search-params-polyfill'

export const wrapRootElement = ({ element }) => (
	<KeyProvider>
		<Apollo>
			<UserProvider>{element}</UserProvider>
		</Apollo>
	</KeyProvider>
)

export const onClientEntry = () => {
	const isBrowser = typeof window !== 'undefined'
	if (process.env.GATSBY_MIXPANEL_TOKEN) {
		mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN)
		if (isBrowser) {
			window.mixpanel = mixpanel
		}
	}
}

export const onInitialClientRender = () => objectFitImages()
