import { useState } from 'react'

const usePersistedState = (initialState, key) => {
	const isBrowser = typeof window !== 'undefined'
	let rawStateFromStorage
	if (isBrowser && window.localStorage) {
		rawStateFromStorage = window.localStorage.getItem(key)
	}

	const stateFromStorage = rawStateFromStorage
	const [value, setter] = useState(stateFromStorage || initialState)

	return [
		value,
		(data) => {
			if (isBrowser && window.localStorage) {
				window.localStorage.setItem(key, data)
			}
			setter(data)
		},
	]
}

export default usePersistedState
