import React, { useEffect, createContext } from 'react'
import usePersistedState from 'helpers/usePersistedState'

export const KeyContext = createContext()

export const KeyProvider = ({ children }) => {
	const [api_key, setApiKey] = usePersistedState(null, 'api_key')
	const isBrowser = typeof window !== 'undefined'

	useEffect(() => {
		const localApiKey =
			isBrowser && window.localStorage && window.localStorage.getItem('api_key')

		if (localApiKey) {
			setApiKey(localApiKey)
			// document.cookie = `api_key=${localApiKey};max-age=604800;domain=barmej.com`
		}
	}, [api_key])

	return (
		<KeyContext.Provider
			value={{
				api_key,
				createSession: (value) => {
					setApiKey(value)
				},
				removeSession: () => {
					setApiKey(null)
					// document.cookie = 'api_key=;domain=barmej.com;max-age=0'
					if (isBrowser && window.localStorage) {
						window.localStorage.clear()
					}
				},
			}}
		>
			{children}
		</KeyContext.Provider>
	)
}
