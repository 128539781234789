import { ApolloLink, ApolloProvider as Provider } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { ApolloClient } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import apolloLogger from 'apollo-link-logger'
import { createUploadLink } from 'apollo-upload-client'
import usePrevious from 'hooks/usePrevious'
import { KeyContext } from 'providers/KeyProvider'
import React, { useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import fetch from 'unfetch'

const Apollo = ({ children }) => {
	const IS_DEV_ENV =
		process.env.NODE_ENV !== 'production' || process.env.GATSBY_DEBUG == 1
	const GRAPHQL_ENDPOINT = `${process.env.GATSBY_API2}v2/graphql`
	const { api_key, removeSession } = useContext(KeyContext)

	const httpLink = createUploadLink({
		fetch,
		uri: GRAPHQL_ENDPOINT,
	})

	const errorLink = onError(({ networkError, graphQLErrors }) => {
		if (graphQLErrors) {
			if (
				api_key &&
				graphQLErrors.some(
					({ message }) =>
						message === 'Not logged!' || message === 'You have no permission'
				)
			) {
				setTimeout(() => {
					toast.error('تم تسجيل خروجك')
				})
				removeSession()

				return
			}
			toast.error('حدث خطأ أثناء محاولة الاتصال بالخادم، يرجى إعادة المحاولة')
			graphQLErrors.map(({ message, locations, path }) =>
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			)
		}
		if (networkError) {
			toast.error(`[Network error]: There is a problem with your internet`)
			console.log(`[Network err]:`, networkError)
		}
	})

	const authLink = setContext((_, { headers }) => {
		const modifiedHeaders = {
			...headers,
			'Accept-Language': 'ar',
		}
		if (api_key) modifiedHeaders.apiKey = api_key
		return {
			headers: modifiedHeaders,
		}
	})

	const link = IS_DEV_ENV
		? ApolloLink.from([apolloLogger, errorLink, authLink.concat(httpLink)])
		: ApolloLink.from([errorLink, authLink.concat(httpLink)])

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link,
	})
	const prevApiKey = usePrevious(api_key)
	useEffect(() => {
		if (prevApiKey && !api_key) {
			// Reset store on logout
			client.clearStore()
		}
	}, [api_key])
	return <Provider client={client}>{children}</Provider>
}

export default Apollo
